<template>
  <b-card class="pt-2 pb-4 px-sm-3 position-relative">
    <!-- TODO: Not hardcoded week -->
    <h5 class="mb-2 text-terciaryy">{{ $t("week") }} 1</h5>
    <div class="text-terciary">
      {{
        challenge.no_of_successful_completes >=
        challenge.no_of_successful_completes_goal
          ? $t("missions.completed")
          : $tc(
              "missions.in_progress",
              challenge.no_of_successful_completes || 0,
              {
                n: challenge.no_of_successful_completes || 0,
                total: challenge.no_of_successful_completes_goal,
              }
            )
      }}
    </div>
    <div class="text-terciary" v-show="can_checkin_any">
      {{ $t("missions.click_day") }}
    </div>

    <div class="mt-3 d-flex flex-column flex-lg-row align-items-center">
      <template v-for="(date, i) in useractivity_sorted">
        <div
          :key="i"
          class="d-flex flex-row-reverse flex-lg-column align-items-center justify-content-between text-center w-100"
        >
          <b-button
            block
            variant="transparent"
            class="p-0 rounded-circle w-auto"
            :key="i"
            :disabled="!can_checkin_any || date.is_checkable != 'Y'"
            @click.prevent="
              date.auto_checkin == 'N' &&
                sendCheckin(new Date(date.report_dt), date.source_hashkey)
            "
          >
            <b-icon-check-circle-fill
              v-if="date.goal_completed != 0"
              class="text-secondary"
              font-scale="3"
              :title="$t('missions.undo_checkin')"
            />
            <b-icon-circle
              v-else
              class="text-secondary"
              :style="{ opacity: 0.5 }"
              font-scale="3"
              :title="$t('missions.check_in')"
            />
          </b-button>

          <div
            class="d-flex flex-column align-items-start align-items-lg-center"
          >
            <small
              class="text-muted text-nowrap mb-1"
              :style="{ opacity: date.is_checkable != 'Y' ? 0.5 : 1 }"
            >
              {{ $t("day") }} {{ i + 1 }}
            </small>
            <b-button
              v-if="date.auto_checkin == 'N'"
              @click="
                date.auto_checkin == 'N' &&
                  sendCheckin(new Date(date.report_dt), date.source_hashkey)
              "
              size="sm"
              class="rounded-pill text-nowrap px-3"
              variant="secondary"
              :disabled="!can_checkin_any || date.is_checkable != 'Y'"
              :title="
                (!can_checkin_any || date.is_checkable != 'Y') &&
                  `${$t('missions.last_report_date')}: ${
                    challenge.last_reporting_dt
                  }`
              "
            >
              {{
                date.source_hashkey ? $t("missions.undo") : $t("missions.done")
              }}
            </b-button>
          </div>
        </div>

        <hr
          :key="-i - 1"
          v-if="i != useractivity.length - 1"
          class="checkin-rule flex-fill border-primary"
        />
      </template>
    </div>

    <div
      v-if="
        challenge.auto_checkin == 'Y' ||
          useractivity.filter(date => date.auto_checkin == 'Y').length
      "
      class="text-center"
    >
      <small class="text-muted">
        {{ $t("missions.auto_checkin") }}
      </small>
    </div>

    <div
      v-if="Boolean(challenge.challenge_tag)"
      class="py-2 px-3 position-absolute d-flex align-items-center text-muted"
      style="bottom: 0; right: 0; gap: 0.75rem;"
    >
      <div>
        {{ challenge.challenge_tag }}
      </div>
    </div>
  </b-card>
</template>

<script>
import { BIconCheckCircleFill, BIconCircle } from "bootstrap-vue"

export default {
  components: { BIconCheckCircleFill, BIconCircle },
  props: [
    "challenge",
    "can_checkin_any",
    "useractivity_sorted",
    "useractivity",
  ],
  methods: {
    sendCheckin(...args) {
      this.$emit("sendCheckin", ...args)
    },
  },
}
</script>
