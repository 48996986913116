<template>
  <b-card class="pt-2 pb-4 px-sm-3 position-relative">
    <b-row>
      <b-col cols="12" md>
        <h4 class="mb-2 text-terciary">{{ $t("week") }} 1</h4>
        <small v-if="challenge.auto_checkin == 'Y'" style="color:#545454">
          {{ $t("missions.auto_checkin") }}
        </small>
      </b-col>
      <b-col
        cols="6"
        md="auto"
        class="text-terciary my-auto font-weight-semibold text-right text-md-left"
      >
        {{
          (challenge.no_of_successful_completes >=
          challenge.no_of_successful_completes_goal
            ? $t("missions.completed")
            : $tc("missions.in_progress", 0)
          )
            .toString()
            .toLocaleLowerCase()
        }}
      </b-col>
      <b-col cols="6" md="auto" class="d-flex align-items-center">
        <template v-for="i in challenge.no_of_successful_completes_goal">
          <hr v-if="i != 1" class="m-0" style="width: 3ch;" :key="`hr-${i}`" />
          <b-icon-check-circle-fill
            v-if="i <= challenge.no_of_successful_completes"
            :key="`filled-${i}`"
            class="text-secondary"
            font-scale="2.5"
            :title="$t('missions.undo_checkin')"
          />
          <b-icon-circle
            v-else
            :key="`circle-${i}`"
            class="text-secondary"
            :style="{ opacity: 0.5 }"
            font-scale="2.5"
            :title="$t('missions.check_in')"
          />
        </template>
      </b-col>
    </b-row>

    <b-overlay :show="loading">
      <PointChart
        :data="filteredData"
        :unit="$t('steps').toString()"
        :goal="goal"
        :zoomDates="false"
      ></PointChart>
    </b-overlay>

    <div
      v-if="Boolean(challenge.challenge_tag)"
      class="py-2 px-3 position-absolute d-flex align-items-center text-muted"
      style="bottom: 0; right: 0; gap: 0.75rem;"
    >
      <div>
        {{ challenge.challenge_tag }}
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from "axios"
import PointChart from "@/components/PointChart.vue"
import { BIconCheckCircleFill, BIconCircle } from "bootstrap-vue"

export default {
  components: { PointChart, BIconCheckCircleFill, BIconCircle },
  props: ["challenge", "goal"],
  data() {
    return {
      graphData: [],
      loading: false,
    }
  },
  created() {
    this.getGraphData()
  },
  computed: {
    filteredData() {
      let data = this.graphData.map(({ day, value }) => {
        const [day_of_month, month] = day.split("/").map(e => parseInt(e, 10))
        let newDate = new Date(
          `${new Date(
            this.challenge.valid_from_dt
          ).getFullYear()}-${month}-${day_of_month}`
        )
        newDate.setHours(0, 0, 0, 0)
        return {
          date: newDate,
          points_activity: value ?? 0,
          points_bonus: 0,
          points_quiz: 0,
        }
      })
      let leftPadding = new Date(data?.[0]?.date)
      leftPadding.setDate(leftPadding.getDate() - 1)
      return [
        {
          date: leftPadding,
          points_activity: 0,
          points_bonus: 0,
          points_quiz: 0,
        },
        ...data,
      ]
    },
  },
  methods: {
    async getGraphData() {
      this.loading = true
      try {
        const response = await axios.get(
          `/missiongraphdata/challenge/${this.$route.params.id}/instance/${this.$route.params.instance}/${this.challenge.valid_from_dt}`
        )
        this.graphData = response.data.data
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.loading = false
      }
    },
    sendCheckin(...args) {
      this.$emit("sendCheckin", ...args)
    },
  },
}
</script>
