<template>
  <b-overlay :show="loading">
    <b-card no-body header-class="pb-0">
      <template #header>
        <h6 class="mb-0">{{ $t("teams.latest_activity") }}</h6>
      </template>
      <div
        style="padding: 0.75rem 1.25rem; max-height: 295px; overflow-y: auto;"
      >
        <b-row
          v-for="(activity, i) in team_activity"
          class="border-light my-3"
          :class="i <= team_activity.length"
          :key="activity.user_id + activity.complete_dt"
        >
          <b-col cols="auto">
            <b-img
              :src="`${activity.file_path}${activity.file_name}`"
              @error="
                event => {
                  event.target.src = $images.noAvatar
                }
              "
              class="rounded-circle"
              style="object-fit: cover; width: 40px; height: 40px;"
              fluid
            />
          </b-col>
          <b-col>
            <small class="d-block font-weight-semibold">
              {{ activity.full_name }}
              {{ `${$t("teams.completed_this")}`.toLocaleLowerCase() }}
            </small>
            <small class="text-muted">
              {{
                dateDiffDays(new Date(), new Date(activity.complete_dt)) - 1 ||
                  ""
              }}
              {{
                `${$tc(
                  "days_ago",
                  dateDiffDays(new Date(), new Date(activity.complete_dt)) - 1
                )}`.toLocaleLowerCase()
              }}
            </small>
          </b-col>
        </b-row>
        <div v-if="!team_activity.length" class="text-muted">
          {{ $t("teams.no_activity") }}
        </div>
        <b-button
          v-if="team_activity_next_page_url"
          block
          variant="transparent"
          size="sm"
          @click="getTeamActivity(team_activity_next_page_url)"
        >
          {{ $t("show_more") }}
        </b-button>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import axios from "axios"

export default {
  data() {
    return {
      /** @type {Array<any>} */
      team_activity: [],
      team_activity_next_page_url: null,

      loading: false,
    }
  },

  created() {
    this.getTeamActivity()
  },

  methods: {
    async getTeamActivity(next_page_url) {
      this.loading = true
      try {
        const response = await axios.get(
          next_page_url || `/challenge/${this.$route.params.id}/activities`
        )
        this.team_activity = response.data.data.sort(function(a, b) {
          let adate = new Date(a.complete_dt)
          let bdate = new Date(b.complete_dt)
          return adate < bdate ? 1 : bdate < adate ? -1 : 0
        })
        this.team_activity_next_page_url = response.data.links.next_page_url
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.loading = false
      }
    },
    dateDiffDays(date1, date2) {
      return Math.ceil(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24))
    },
  },
}
</script>
