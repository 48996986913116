<template>
  <b-card
    body-class="p-5.5 d-flex flex-column align-items-center justify-content-center position-relative"
  >
    <h5 class="mb-4 text-terciary text-center">
      {{
        challenge.is_completed == "N"
          ? $t("missions.not_completed")
          : $t("missions.you_completed")
      }}
    </h5>
    <b-icon-circle
      v-if="challenge.is_completed == 'N'"
      class="mb-4 text-secondary"
      font-scale="5"
    />
    <b-icon-check-circle-fill v-else class="mb-4 text-secondary" font-scale="5" />
    <div v-if="challenge.auto_checkin != 'Y'">
      <b-button
        :disabled="!can_checkin_any"
        variant="secondary"
        v-if="challenge.is_completed == 'N'"
        @click="sendCheckin()"
      >
        {{ $t("missions.did_this") }}
      </b-button>
      <b-button
        :disabled="!can_checkin_any"
        :title="
          !can_checkin_any &&
            `${$t('missions.last_report_date')}: ${challenge.last_reporting_dt}`
        "
        variant="secondary"
        v-else
        @click="sendCheckin(undefined, challenge.first_source_hashkey)"
      >
        {{ $t("missions.undo_checkin") }}
      </b-button>
    </div>
    <div v-else>
      {{ $t("missions.auto_checkin") }}
    </div>

    <div
      v-if="Boolean(challenge.challenge_tag)"
      class="py-2 px-3 position-absolute d-flex align-items-center text-muted"
      style="bottom: 0; right: 0; gap: 0.75rem;"
    >
      <div>
        {{ challenge.challenge_tag }}
      </div>
    </div>
  </b-card>
</template>

<script>
import { BIconCheckCircleFill, BIconCircle } from "bootstrap-vue"

export default {
  components: { BIconCheckCircleFill, BIconCircle },
  props: ["challenge", "can_checkin_any"],
  methods: {
    sendCheckin(...args) {
      this.$emit("sendCheckin", ...args)
    },
  },
}
</script>
