var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":Boolean(_vm.loading)}},[_c('b-container',{staticClass:"my-2 my-sm-5"},[_c('b-button',{staticClass:"mb-4 text-terciary",attrs:{"to":"/challenge","variant":"transparent"}},[_vm._v(" < "+_vm._s(_vm.$t("go_back"))+" ")]),_c('b-card',{staticClass:"card-img-responsive",attrs:{"header-class":"p-0 rounded overflow-hidden","img-start":!_vm.imageToSide && _vm.challenge.has_live_media == 'N',"img-src":_vm.challenge.has_live_media == 'N'
          ? ("" + (_vm.challenge.file_path) + (_vm.challenge.file_name))
          : null,"img-width":"100%","title":_vm.challenge.name},scopedSlots:_vm._u([(_vm.challenge.has_live_media == 'Y')?{key:"header",fn:function(){return [_c('b-embed',{attrs:{"type":_vm.challenge.media_source == 'S3' ? 'video' : 'iframe',"controls":"","preload":"metadata","src":("" + (_vm.challenge.live_media_file_path) + (_vm.challenge.live_media_file_name))}},[_vm._v(" Not supported ")])]},proxy:true}:null],null,true)},[_c('div',{staticClass:"text",staticStyle:{"color":"#545454"}},[_c('b-row',{staticClass:"align-items-center",staticStyle:{"row-gap":"0.5rem"}},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-between flex-wrap",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon-calendar3',{staticClass:"mr-3"}),_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("daymonth")(_vm.challenge.valid_from_dt))+" - "+_vm._s(_vm._f("daymonth")(_vm.challenge.valid_to_dt))+" ")])],1),_c('div',{staticClass:"d-flex align-items-center",attrs:{"title":_vm.$t('missions.last_report_date')}},[_c('b-icon-calendar-x',{staticClass:"mr-3"}),_c('div',[_vm._v(" "+_vm._s(((_vm.$t("missions.last_report_date")) + ": "))+" "+_vm._s(_vm.challenge.last_reporting_dt)+" ")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon-flag-fill',{staticClass:"mr-3"}),_c('div',[_vm._v(_vm._s(_vm.challenge.description_exhortion))])],1)])],1),_c('div',{staticClass:"mt-3 d-flex flex-column flex-lg-row align-items-center",style:(_vm.fullDescription ? 'white-space: pre-line;' : '')},[_vm._v(" "+_vm._s(_vm.truncated(_vm.challenge.description, _vm.descriptionMax, _vm.fullDescription))+" "),(
              _vm.challenge.description &&
                _vm.challenge.description.length > _vm.descriptionMax
            )?_c('b-button',{staticClass:"ml-3 d-flex align-items-center",attrs:{"variant":"link"},on:{"click":function($event){_vm.fullDescription = !_vm.fullDescription}}},[_c('span',{staticClass:"mr-1 text-nowrap"},[_vm._v(" "+_vm._s(_vm.fullDescription ? _vm.$t("read_less") : _vm.$t("read_more"))+" ")]),_c('b-icon-chevron-down',{staticClass:"animate",class:{ flip: _vm.fullDescription }})],1):_vm._e()],1)],1)]),_c('b-row',{staticClass:"mt-4",staticStyle:{"gap":"1.5rem"}},[_c('b-col',[(_vm.challenge.type == 'CHECKIN')?_c('Multicheckin',{attrs:{"challenge":_vm.challenge,"can_checkin_any":_vm.can_checkin_any,"useractivity":_vm.useractivity,"useractivity_sorted":_vm.useractivity_sorted},on:{"sendCheckin":_vm.sendCheckin}}):(
            _vm.challenge.type == 'STEPS' || _vm.challenge.show_progress_graph == 'Y'
          )?_c('MulticheckinGraph',{attrs:{"challenge":_vm.challenge,"goal":(_vm.goalperiod || {}).activity_quantity_goal},on:{"sendCheckin":_vm.sendCheckin}}):_c('Onetime',{attrs:{"challenge":_vm.challenge,"can_checkin_any":_vm.can_checkin_any},on:{"sendCheckin":_vm.sendCheckin}}),_c('Milestones',{attrs:{"challenge":_vm.challenge}})],1),_c('b-col',{staticClass:"mt-4 mt-xl-0",attrs:{"cols":"12","xl":_vm.$refs.teamActivity && _vm.$refs.teamActivity.team_activity.length
            ? 5
            : 'auto'}},[_c('TeamActivity',{ref:"teamActivity"})],1)],1),_c('b-form-invalid-feedback',{staticClass:"mt-3",attrs:{"state":_vm.error ? false : null}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('b-modal',{attrs:{"id":"rateChallenge","title":("🎉 " + (_vm.$t('missions.mission_completed'))),"title-class":"h3","centered":"","lazy":"","ok-only":"","ok-disabled":_vm.rateLoading,"ok-title":_vm.$t('confirm')},on:{"ok":_vm.submitRating}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("missions.completed_congratulations"))+" ")]),_c('hr'),_c('p',[_vm._v(" "+_vm._s(_vm.$t("missions.liked_challenge"))+" ")]),_c('b-overlay',{attrs:{"show":_vm.rateLoading}},[_c('b-form-rating',{attrs:{"variant":"primary","stars":"5","size":"lg","no-border":""},model:{value:(_vm.challengeRating),callback:function ($$v) {_vm.challengeRating=$$v},expression:"challengeRating"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }