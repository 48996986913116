<template>
  <b-card class="p-2 mt-4" v-if="challenge.has_milestones != 'N'">
    <b-row>
      <b-col>
        <h4 class="mb-3 text-primary">
          {{ $t("missions.milestone") }}
        </h4>
        <div class="text-muted">
          {{
            $tc(
              "missions.completed_milestones",
              milestones.filter(e => e.complete_dt).length
            )
          }}
        </div>
      </b-col>

      <b-col cols="auto" class="d-flex align-items-center">
        <template v-for="(milestone, i) in milestones">
          <div :key="milestone.id" cols="auto" class="text-pink text-center">
            <b-icon-check-circle-fill
              v-if="milestone.complete_dt"
              class="mb-2"
              font-scale="4"
            />
            <b-icon-check-circle v-else class="mb-2" font-scale="4" />
            <div class="h5">{{ $t("week") }} {{ milestone.week_number }}</div>
          </div>
          <hr
            :key="milestone.id"
            v-if="i != milestones.length - 1"
            style="width: 5ch; padding-bottom: 2em;"
          />
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios"
import { BIconCheckCircle, BIconCheckCircleFill } from "bootstrap-vue"

export default {
  components: { BIconCheckCircle, BIconCheckCircleFill },

  props: ["challenge"],
  data() {
    return {
      /** @type {Array<any>} */
      milestones: [],

      loading: false,
    }
  },

  created() {
    this.getMilestones()
  },

  methods: {
    async getMilestones() {
      this.loading = true
      try {
        const response = await axios.get(
          `/milestones/challenge/${this.$route.params.id}/instance/${this.$route.params.instance}`
        )
        this.milestones = response.data.data
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
