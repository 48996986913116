<template>
  <b-overlay :show="Boolean(loading)">
    <b-container class="my-2 my-sm-5">
      <b-button to="/challenge" class="mb-4 text-terciary" variant="transparent">
        &lt; {{ $t("go_back") }}
      </b-button>

      <b-card
        header-class="p-0 rounded overflow-hidden"
        class="card-img-responsive"
        :img-start="!imageToSide && challenge.has_live_media == 'N'"
        :img-src="
          challenge.has_live_media == 'N'
            ? `${challenge.file_path}${challenge.file_name}`
            : null
        "
        img-width="100%"
        :title="challenge.name"
      >
        <template #header v-if="challenge.has_live_media == 'Y'">
          <b-embed
            :type="challenge.media_source == 'S3' ? 'video' : 'iframe'"
            controls
            preload="metadata"
            :src="
              `${challenge.live_media_file_path}${challenge.live_media_file_name}`
            "
          >
            Not supported
          </b-embed>
        </template>

        <div class="text" style="color:#545454">
          <b-row class="align-items-center" style="row-gap: 0.5rem;">
            <b-col
              class="d-flex align-items-center justify-content-between flex-wrap"
              style="gap: 1rem;"
            >
              <div class="d-flex align-items-center">
                <b-icon-calendar3 class="mr-3" />
                <div class="text-nowrap">
                  {{ challenge.valid_from_dt | daymonth }} -
                  {{ challenge.valid_to_dt | daymonth }}
                </div>
              </div>

              <div
                class="d-flex align-items-center"
                :title="$t('missions.last_report_date')"
              >
                <b-icon-calendar-x class="mr-3" />
                <div>
                  {{ `${$t("missions.last_report_date")}: ` }}
                  {{ challenge.last_reporting_dt }}
                </div>
              </div>

              <div class="d-flex align-items-center">
                <b-icon-flag-fill class="mr-3" />
                <div>{{ challenge.description_exhortion }}</div>
              </div>
            </b-col>
          </b-row>

          <div
            class="mt-3 d-flex flex-column flex-lg-row align-items-center"
            :style="fullDescription ? 'white-space: pre-line;' : ''"
          >
            {{
              truncated(challenge.description, descriptionMax, fullDescription)
            }}
            <b-button
              v-if="
                challenge.description &&
                  challenge.description.length > descriptionMax
              "
              variant="link"
              class="ml-3 d-flex align-items-center"
              @click="fullDescription = !fullDescription"
            >
              <span class="mr-1 text-nowrap">
                {{ fullDescription ? $t("read_less") : $t("read_more") }}
              </span>
              <b-icon-chevron-down
                class="animate"
                :class="{ flip: fullDescription }"
              />
            </b-button>
          </div>
        </div>
      </b-card>

      <b-row class="mt-4" style="gap: 1.5rem">
        <b-col>
          <Multicheckin
            v-if="challenge.type == 'CHECKIN'"
            :challenge="challenge"
            :can_checkin_any="can_checkin_any"
            :useractivity="useractivity"
            :useractivity_sorted="useractivity_sorted"
            @sendCheckin="sendCheckin"
          ></Multicheckin>

          <MulticheckinGraph
            v-else-if="
              challenge.type == 'STEPS' || challenge.show_progress_graph == 'Y'
            "
            :challenge="challenge"
            :goal="(goalperiod || {}).activity_quantity_goal"
            @sendCheckin="sendCheckin"
          >
          </MulticheckinGraph>

          <Onetime
            v-else
            :challenge="challenge"
            :can_checkin_any="can_checkin_any"
            @sendCheckin="sendCheckin"
          ></Onetime>

          <Milestones :challenge="challenge" />
        </b-col>

        <b-col
          cols="12"
          :xl="
            $refs.teamActivity && $refs.teamActivity.team_activity.length
              ? 5
              : 'auto'
          "
          class="mt-4 mt-xl-0"
        >
          <TeamActivity ref="teamActivity" />
        </b-col>
      </b-row>

      <b-form-invalid-feedback :state="error ? false : null" class="mt-3">
        {{ error }}
      </b-form-invalid-feedback>

      <b-modal
        id="rateChallenge"
        :title="`🎉 ${$t('missions.mission_completed')}`"
        title-class="h3"
        centered
        lazy
        ok-only
        @ok="submitRating"
        :ok-disabled="rateLoading"
        :ok-title="$t('confirm')"
      >
        <p>
          {{ $t("missions.completed_congratulations") }}
        </p>
        <hr />
        <p>
          {{ $t("missions.liked_challenge") }}
        </p>
        <b-overlay :show="rateLoading">
          <b-form-rating
            v-model="challengeRating"
            variant="primary"
            stars="5"
            size="lg"
            no-border
          ></b-form-rating>
        </b-overlay>
      </b-modal>
    </b-container>
  </b-overlay>
</template>

<script>
import axios from "axios"
import {
  BIconCalendar3,
  BIconCalendarX,
  BIconFlagFill,
  BIconChevronDown,
} from "bootstrap-vue"
import TeamActivity from "@/components/Challenge/TeamActivity.vue"
import Milestones from "@/components/Challenge/Milestones.vue"
import Multicheckin from "@/components/Challenge/Multicheckin.vue"
import MulticheckinGraph from "@/components/Challenge/MulticheckinGraph.vue"
import Onetime from "@/components/Challenge/Onetime.vue"

export default {
  components: {
    BIconCalendar3,
    BIconCalendarX,
    BIconFlagFill,
    BIconChevronDown,
    TeamActivity,
    Milestones,
    Multicheckin,
    MulticheckinGraph,
    Onetime,
  },
  data() {
    return {
      /** @type {Object<any>} */
      header: {},
      /** @type {Object<any>} */
      challenge: {},
      /** @type {Object<any>} */
      goalperiod: {},
      /** @type {Array<any>} */
      useractivity: [],
      loading: false,
      error: "",
      fullDescription: false,
      descriptionMax: 75,
      imageToSide: false,

      rateLoading: false,
      challengeRating: 0,
    }
  },

  async created() {
    Promise.all([
      this.getChallenge(),
      this.getGoalPeriod(), // Used for activity_quantity_goal
      this.getUseractivity(),
    ])
    this.imageToSide = ["xs", "sm", "md", "lg"].includes(this.$breakpoint())
    window.addEventListener("resize", () => {
      this.imageToSide = ["xs", "sm", "md", "lg"].includes(this.$breakpoint())
    })
  },

  computed: {
    /** @return {any[]} */
    useractivity_sorted() {
      let sorted = [...this.useractivity].sort(
        (a, b) => new Date(a.report_dt) > new Date(b.report_dt)
      )
      return sorted
    },
    /**
     * This is 00:00 on the day after actual date, so compare using less than (not less-than-or-equals)
     * @return {Date} */
    last_reporting_dt_iso() {
      let date = new Date(
        `${this.challenge.last_reporting_dt} ${new Date().getFullYear()}`
      )
      date.setHours(24) // increment to next day
      return date
    },
    /** @returns {boolean} */
    can_checkin_any() {
      // False if autocheckin
      if (this.challenge.auto_checkin == "Y") {
        return false
      }
      // False if last reporting has not passed
      if (new Date() >= this.last_reporting_dt_iso) {
        return false
      }
      if (this.useractivity?.length) {
        return this.useractivity.find(({ is_checkable }) => is_checkable == "Y")
      }
      return false
    },
  },

  methods: {
    truncated(text, length, truncate) {
      if (!text) return ""
      if (truncate) {
        return text
      } else {
        return text.length > length ? text.slice(0, length) + "..." : text
      }
    },
    async submitRating(event) {
      if (!this.challengeRating) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: this.$t("missions.please_rate"),
        })
        event.preventDefault()
        return false
      }

      this.rateLoading = true
      try {
        await axios.post("/ratechallenge", {
          challenge_id: parseInt(this.challenge.challenge_id, 10),
          rating: this.challengeRating,
        })
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.rateLoading = false
      }
      return true
    },

    async sendCheckin(date, source_hashkey) {
      let response = undefined

      this.loading += true
      this.error = ""
      try {
        if (!date) {
          let today = new Date()
          let validFromDate = new Date(this.challenge.valid_from_dt)
          let validToDate = new Date(this.challenge.valid_to_dt)
          if (today >= validFromDate && today <= validToDate) {
            date = today
          } else if (today > validToDate) {
            // Challenge expired but before last report dt
            date = validToDate
          }
        }

        let data = {
          activity_id: parseInt(this.challenge.activity_id, 10),
          activity_quantity: 1,
          user_campaign_version_id: parseInt(
            this.challenge.user_campaign_version_id,
            10
          ),
          report_dt: date.toISOString().split("T")[0],
          challenge_id: parseInt(this.challenge.challenge_id, 10),
          user_challenge_id: this.$route.params.instance,
        }
        if (source_hashkey) {
          data["source_hashkey"] = source_hashkey
        }
        response = await axios.post("/useractivity/check", data)
        if (response.data?.challenge_completed) {
          setTimeout(() => this.$store.dispatch("showConfetti"), 1000)
          setTimeout(() => this.$store.dispatch("showConfetti"), 1500)

          // Show rate challenge modal (id=rateChallenge)
          this.$bvModal.show("rateChallenge")

          if (response.data?.points_reward)
            this.$store.dispatch("addToastMessage", {
              type: "success",
              text: this.$tc("points_registered", response.data?.points_reward),
              dismissSecs: 10,
            })
        }
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
        this.error += `${name}: ${message}`
      } finally {
        this.loading -= true
        await Promise.all([
          this.getChallenge(),
          this.getUseractivity(),
          this.$refs?.teamActivity?.getTeamActivity?.(),
        ])
      }
      // Change to `response.data.challenge_completed` for confetti on completing whole challenge
      // If response data points is not null, else use source_hashkey presence
      if (
        response?.data?.points === undefined
          ? !source_hashkey
          : response?.data?.points !== null
      ) {
        setTimeout(() => this.$store.dispatch("showConfetti"), 200)
        this.$store.dispatch("addToastMessage", {
          type: "success",
          text: this.$tc("points_registered", response.data?.points),
        })
      }
    },
    async getChallenge() {
      this.loading += true
      try {
        const response = await axios.get(
          `/challenge/${this.$route.params.id}/instance/${this.$route.params.instance}`
        )
        this.challenge = response.data.data[0]
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
        // Redirect back to challenges if it doesn't exist
        this.$router.push("/challenge")
      } finally {
        this.loading -= true
      }
    },
    async getGoalPeriod() {
      this.loading += true
      try {
        const response = await axios.get(
          `/goalperiod/challenge/${this.$route.params.id}/instance/${this.$route.params.instance}`
        )
        this.goalperiod = response.data.data
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.loading -= true
      }
    },

    async getUseractivity() {
      this.loading += true
      try {
        const response = await axios.get(`/useractivity/check`, {
          params: {
            challenge_id: this.$route.params.id,
            user_challenge_id: this.$route.params.instance,
          },
        })
        this.useractivity = response.data.data
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.loading -= true
      }
    },
  },

  filters: {
    daymonth(val) {
      let date = new Date(val)
      return date.toLocaleDateString(undefined, {
        // year: "numeric",
        month: "short",
        day: "2-digit",
      })
    },
  },
}
</script>

<style scoped>
.checkin-rule {
  opacity: 0.7;
  min-width: 1ch;
  margin-top: 1.5rem;
  align-self: flex-start;
  width: 100%;
}
@media (max-width: 768px) {
  .checkin-rule {
    opacity: 0.3;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}
</style>
